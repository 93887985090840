<template>
  <div>

    <!-- Header: Personal Info -->
    <div class="d-flex">
      <feather-icon
        icon="UserIcon"
        size="19"
      />
      <h4 class="mb-0 ml-50">
        Informations personnelles
      </h4>
    </div>

    <!-- Form: Personal Info Form -->
    <b-form class="mt-1">
      <b-row>

        <!-- Field: Mobile -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Téléphone"
            label-for="mobile"
          >
            <b-form-input
              id="mobile"
              v-model="userData.phone"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Website -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Site web"
            label-for="website"
          >
            <b-form-input
              id="website"
              v-model="userData.website"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Header: Personal Info -->
      <div class="d-flex mt-2">
        <feather-icon
          icon="MapPinIcon"
          size="19"
        />
        <h4 class="mb-0 ml-50">
          Adresse de facturation
        </h4>
      </div>

      <!-- Form: Personal Info Form -->
      <b-row class="mt-1">

        <!-- Field: Address Line 1 -->
        <b-col
          cols="12"
          md="12"
          lg="12"
        >
          <b-form-group
            label="Addresse"
            label-for="address-line-1"
          >
            <b-form-input
              id="address-line-1"
              v-model="userData.adresse"
              placeholder="3 Rue du Faon d'Or"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Postcode -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Code postal"
            label-for="postcode"
          >
            <b-form-input
              id="postcode"
              v-model="userData.codepostal"
              type="number"
              placeholder="75010"
            />
          </b-form-group>
        </b-col>

        <!-- Field: City -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Ville"
            label-for="city"
          >
            <b-form-input
              id="city"
              v-model="userData.ville"
              placeholder="Paris"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Country -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Pays"
            label-for="country"
          >
            <b-form-input
              id="country"
              v-model="userData.pays"
              placeholder="France"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col>
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="modify()"
          >
            Enregistrer
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BRow, BCol, BForm, BFormGroup, BFormInput, BButton,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    modify() {
      store.dispatch('app-user/modifyUser', this.userData)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Compte à jour',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: 'Le compte a été mis à jour avec succès !',
            },
          })
        })
        .catch(() => {
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<template>
  <div>

    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="userData.avatar"
          :text="avatarText(userData.fullName)"
          :variant="`light-${resolveUserRoleVariant(userData.role)}`"
          size="90px"
          rounded
        />
      </template>
      <h4>
        {{ userData.fullName }}
      </h4>
    </b-media>

    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>

        <!-- Field: Username -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="E-mail"
            label-for="username"
          >
            <b-form-input
              id="username"
              v-model="userData.username"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Full Name -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Nom complet"
            label-for="full-name"
          >
            <b-form-input
              id="full-name"
              v-model="userData.fullName"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Facebook Manager Id"
            label-for="facebookmanagerid"
          >
            <b-form-input
              id="facebookmanagerid"
              v-model="userData.facebookmanagerid"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Google Data Studio Id"
            label-for="googledatastudioid"
          >
            <b-form-input
              id="googledatastudioid"
              v-model="userData.googledatastudioid"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Affaire Pipedrive Id"
            label-for="pipedrive"
          >
            <b-form-input
              id="pipedrive"
              v-model="userData.pipedrive"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Role -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Ops Fb Ads"
            label-for="user-role"
          >
            <v-select
              v-model="userData.opsfbads"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="adminOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="user-role"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Ops G Ads"
            label-for="user-role"
          >
            <v-select
              v-model="userData.opsgads"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="adminOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="user-role"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Offre en cours"
            label-for="user-role"
          >
            <v-select
              v-model="userData.offre"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="offreOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="user-role"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <!-- PERMISSION TABLE -->
    <!-- <b-card
      no-body
      class="border mt-1"
    >
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon
            icon="LockIcon"
            size="18"
          />
          <span class="align-middle ml-50">Permission</span>
        </b-card-title>
      </b-card-header>
      <b-table
        striped
        responsive
        class="mb-0"
        :items="permissionsData"
      >
        <template #cell(module)="data">
          {{ data.value }}
        </template>
        <template #cell()="data">
          <b-form-checkbox :checked="data.value" />
        </template>
      </b-table>
    </b-card> -->

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="modify()"
    >
      Enregistrer
    </b-button>
  </div>
</template>

<script>
import axios from '@axios'
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import useUsersList from '../users-list/useUsersList'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    vSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      adminOptions: [],
    }
  },
  setup(props) {

    const { resolveUserRoleVariant } = useUsersList()

    const offreOptions = [
      { label: 'FB Ads', value: 0 },
      { label: 'G Ads', value: 1 },
      { label: 'FB Ads + G Ads', value: 2 },
    ]

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.userData.avatar = base64
    })

    return {
      resolveUserRoleVariant,
      avatarText,
      offreOptions,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
  methods: {
    modify() {
      store.dispatch('app-user/modifyUser', this.userData)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Compte à jour',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: 'Le compte a été mis à jour avec succès !',
            },
          })
        })
        .catch(() => {
        })
    },
  },
  created() {
    axios
      .get('https://app2.oscar-black.com/api/getops')
      .then(response => {
        for (var i = 0; i < response.data.length ; i++) {
          this.adminOptions.push({
            label: response.data[i].name,
            value: response.data[i].id,
          })
        }
        console.log(response)
      })
      .catch(() => {

      })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('https://app2.oscar-black.com/api/users', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`https://app2.oscar-black.com/api/user/${id}`)
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('https://app2.oscar-black.com/api/user', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    modifyUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`https://app2.oscar-black.com/api/user/${userData.id}`, userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    newPassword(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`https://app2.oscar-black.com/api/user/updatepassword/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
